:global{
  .adm-input, .adm-text-area {
    border: 1px solid #00000026;
    border-radius: 6px;
    padding: 8px;
    box-sizing: border-box;
    input, textarea {
      font-size: 14px;
    }
  }
  .adm-button {
    font-size: 16px!important;
  }
}
