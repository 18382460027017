@import './var';
@import './reset';
@import './antd';


:global(.text-ellipsis) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.space-padding {
  padding-right: 32px !important;
  width: 260px;
  text-align: right;
}

body {
  background-color: #f6f7fb;
}
